
const scrollToTop = () => {
  window.scrollTo({
    left: 0,
    top: 0,
  })
}

const WHATS_APP_LINK: string = 'https://api.whatsapp.com/send/?phone=573172619992&text=hola&type=phone_number&app_absent=0'
// const WHATS_APP_LINK: string = ''
const EMAIL: string = 'holayaapp@gmail.com';
const PHONE: string = '+51 17028914';
// const WHATS_APP: string = '+51 988884183';
const WHATS_APP: string = '+57 3172619992';
const TIME: string = 'Lun-Sáb 8:30-18:00';
const APP_LINK : string = 'https://prod-peruloan-public-r-w.obs.la-north-2.myhuaweicloud.com/apk/holaya/Holaya_1.0.0.apk';

export {
  scrollToTop,
  WHATS_APP_LINK,
  EMAIL,
  PHONE,
  TIME,
  APP_LINK,
  WHATS_APP,
}
